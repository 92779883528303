import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'

import VueAxios from "vue-axios";
import axios from "axios";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import babelPolyfill from 'babel-polyfill'

import Vuetify from 'vuetify'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import VueStepWizard from 'vue-step-wizard'
import 'vue-step-wizard/dist/vue-step-wizard.css'


import _ from 'lodash'


import VueClipboard from 'vue-clipboard2'


Vue.use(_)

Vue.use(VueToast)

Vue.use(VueClipboard)

Vue.use(VueStepWizard);

// import Swal from 'sweetalert2'

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(Vuetify)

// Vue.use(Swal)

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

Vue.use(VueAxios, axios);

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
