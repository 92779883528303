import {LMS} from "@/axios.js";
import axios from 'axios'

export default {
    async getTokenFromBe(redirect_uri, queryCode){
      console.log("🚀 ~ getTokenFromBe ~ redirect_uri, queryCode", redirect_uri, queryCode)
      let response = null
      await LMS.post(`api/getAccessToken`,
      {
          redirect_uri:redirect_uri,
          code: queryCode
      })
      .then(res => {
        console.log(res)
        if (res.data) {
          console.log("getTokenFromBe(API):",res.data)
          response = res.data
        }
      })
      .catch(e => {
          console.log(e);
      });
      return response
    },
    async getUserInGraph(accessToken){
      var config = {
        method: 'get',
        url: 'https://graph.microsoft.com/v1.0/me',
        headers: { 
          'SdkVersion': 'postman-graph/v2.0', 
          'Authorization': `Bearer ${accessToken}`
        }
      };

      let response = await axios(config)
      .then(function (response) {
        if(response.data){
          // console.log(response.data);
          return response.data
        }
      })
      .catch(function (error) {
        console.log(error);
        return error
      });
      return response
    },
    async getTokenFromGraph(queryCode){
      console.log("🚀 ~ getTokenFromGraph ~ redirect_uri, queryCode", queryCode)
        var response = await axios.post(`https://login.microsoftonline.com/cf81f1df-de59-4c29-91da-a2dfd04aa751/oauth2/v2.0/token`,
        {
          'scope': `user.read`,
          'code': queryCode,
          'redirect_uri': `http://localhost:8081/callback`,
          'grant_type': `authorization_code`,
          'client_id': `46e43cd0-a22a-47fb-90b3-bfbbd77ed4fc`,
          'client_secret': `a03IV~y2y.PrOCP7UpxrI_wU7UTX-f~mLR`
          
        }
        )
        .then(res => {
          if (res.data) {
            console.log("getTokenFromGraph(API):",res.data)
            return res.data
          }
        })
        .catch(e => {
          console.log(e);
        });
        return response
    },
}