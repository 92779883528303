import axios from 'axios';
const basicAuth = 'Basic ' + btoa(process.env.VUE_APP_EXAM_API_USER + ':' + process.env.VUE_APP_EXAM_API_PASSWORD);
const basicAuth_REG = 'Basic ' + btoa(process.env.VUE_APP_REG_API_USER + ':' + process.env.VUE_APP_REG_API_PASSWORD);
const basicAuth_LMS = 'Basic ' + btoa(process.env.VUE_APP_LMS_MANAGEMENT_API_USER + ':' + process.env.VUE_APP_LMS_MANAGEMENT_API_PASSWORD);
export const HTTP = axios.create({
  baseURL: 'https://api.tlic.cmu.ac.th/exam-manager/',
  headers: {
    'Authorization': basicAuth
  },
});

export const LMS = axios.create({
  // baseURL: 'https://lmsmng.tlic.cmu.ac.th/lrv/',
  // baseURL: 'http://10.110.3.88:80/',
  // baseURL: 'http://10.151.0.158:80/',
  baseURL: process.env.VUE_APP_BACKEND_URL,
  headers: {
    'Authorization': basicAuth_LMS
  },
});

export const REG = axios.create({
  baseURL: 'https://api.reg.cmu.ac.th/',
  headers: {
    'Authorization': basicAuth_REG
  },
});

export const GRADE_REG = axios.create({
  baseURL: 'https://api.reg.cmu.ac.th/',
  headers: {
    'Authorization': basicAuth_REG
  },
});

export const AUTH = axios.create({
  baseURL: 'https://auth-tlic.tlic.cmu.ac.th/',
  // baseURL: 'https://ronin-test.tlic.cmu.ac.th/',
  headers: {
    'Authorization': basicAuth
  },
});

export const GRAPH = axios.create({
  baseURL: 'https://graph.microsoftonline.com/',
  // baseURL: 'https://ronin-test.tlic.cmu.ac.th/',
});

export const AZURE = axios.create({
  baseURL: 'https://login.microsoftonline.com/',
  // baseURL: 'https://ronin-test.tlic.cmu.ac.th/',
});
