import Vue from 'vue'
import Router from 'vue-router'

//Token Check
import Auth from '@/services/Auth'

// Admin
const ChangeEmail = () => import('@/views/admin/ChangeEmail')
const CreateCourse = () => import('@/views/admin/CreateCourse')

const CryptoJS = require("crypto-js")

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Views - Pages
const PageNopermission = () => import('@/views/pages/PageNoPermission')
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Students
const CheckIn = () => import('@/views/students/CheckIn')

// Auth
const Callback = () => import('@/views/auth/Callback')
const Signout = () => import('@/views/auth/SignOut')
const Signin = () => import('@/views/auth/SignIn')

// Moodle
const MoodleList = () => import('@/views/kc-moodle/MoodleList')
// const MoodleDetail = () => import('@/views/kc-moodle/MoodleDetail')

// Canvas
const CanvasList = () => import('@/views/canvas/CanvasList')

// Mango
const MangoList = () => import('@/views/mango/MangoList')

// Exam
const ExamList = () => import('@/views/exam-cmu/ExamList')

// TroubleShooting Guide
const Guide = () => import('@/views/pages/Guide')

Vue.use(Router)

// export default new Router({
const router =  new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  // console.log("Router");
  return [
    {
      path: '/',
      redirect: '/announcement',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'announcement',
          name: 'Announcement',
          component: Dashboard
        },
        // {
        //   path: 'admin',
        //   component: {
        //     render(c) {
        //       return c('router-view')
        //     }
        //   },
        //   children: [
        //     {
        //       path: '/change-email',
        //       name: 'เปลี่ยน Email เป็นอาจารย์',
        //       component: ChangeEmail
        //     },{
        //       path: '/create-course',
        //       name: 'เปิดคอร์ส',
        //       component: CreateCourse
        //     },
        //   ]
        // },
        // {
        //   path: 'kc-moodle',
        //   component: {
        //     render(c) {
        //       return c('router-view')
        //     }
        //   },
        //   children: [
        //     {
        //       path: '',
        //       name: 'KC-Moodle',
        //       component: MoodleList
        //     },
        //   ]
        // },
        // {
        //   path: 'canvas',
        //   component: {
        //     render(c) {
        //       return c('router-view')
        //     }
        //   },
        //   children: [
        //     {
        //       path: '',
        //       name: 'Canvas',
        //       component: CanvasList
        //     },
        //   ]
        // },
        {
          path: 'mango',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Mango',
              component: MangoList
            },
          ]
        },
        // {
        //   path: 'exam',
        //   component: {
        //     render(c) {
        //       return c('router-view')
        //     }
        //   },
        //   children: [
        //     {
        //       path: '',
        //       name: 'Exam',
        //       component: ExamList
        //     },
        //   ]
        // },
        // {
        //   path: 'troubleshooting',
        //   component: {
        //     render(c) {
        //       return c('router-view')
        //     }
        //   },
        //   children: [
        //     {
        //       path: '',
        //       name: 'Guide',
        //       component: Guide
        //     },
        //   ]
        // },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'nopermission',
          name: 'Nopermission',
          component: PageNopermission
        },
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    },
    {
      path: '/students',
      redirect: '/students/check-in',
      name: 'Students',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'check-in',
          name: 'Check-in',
          component: CheckIn
        },
      ]
    },
    {
      path: '/callback',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '/',
          name: 'Callback',
          component: Callback
        },
      ]
    },
    {
      path: '/signout',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '/',
          name: 'Signout',
          component: Signout
        },
      ]
    },
    {
      path: '/signin',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '/',
          name: 'Signin',
          component: Signin
        },
      ]
    },
    {
      path: '/login',
      redirect: '/',
    },
    {
      path: '*',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '/',
          name: '404',
          component: Page404
        },
      ]
    }
  ]
}

router.beforeEach((to, from, next) => {
  /*if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
  else next()*/
  let Acctype = ""
  let isAdmin = ""
  let token = ""
  try {
    if( localStorage.getItem('AccType') !== null ){
      Acctype = (CryptoJS.AES.decrypt(localStorage.getItem('AccType'), process.env.VUE_APP_CRYPTO_KEY)).toString(CryptoJS.enc.Utf8)
    }else{
      Acctype = null
    }
    if( localStorage.getItem('Admin') !== null ){
      isAdmin = (CryptoJS.AES.decrypt(localStorage.getItem('Admin'), process.env.VUE_APP_CRYPTO_KEY)).toString(CryptoJS.enc.Utf8)
    }else{
      isAdmin = null
    }
    if( localStorage.getItem('access_token') !== null ){
      token = (CryptoJS.AES.decrypt(localStorage.getItem('access_token'), process.env.VUE_APP_CRYPTO_KEY)).toString(CryptoJS.enc.Utf8)
    }else{
      token = null
    }
  } catch (error) {
    console.log(error)
    localStorage.removeItem('code')
    localStorage.removeItem('access_token')
    localStorage.removeItem('email')
    localStorage.removeItem('name')
    localStorage.removeItem('AccType')
    localStorage.removeItem('Admin')
    next({ name: 'Signin' })
  }
  console.log("token:",(typeof token !== 'undefined'))
  if(isAdmin === 'admin'){
    console.log("Admin:",isAdmin)
  }
  console.log("Acctype:",Acctype)
  console.log("to.name:",to.name)
  console.log("to",to)

  if (token !== null){
    Auth.getUserInGraph(token).then(user => {
      console.log("🚀 ~ file: index.js ~ line 510 ~ Auth.getUserInGraph ~ user", user)
      if(user instanceof Error){
        console.log("Token Expired")
        localStorage.removeItem('code')
        localStorage.removeItem('access_token')
        localStorage.removeItem('email')
        localStorage.removeItem('name')
        localStorage.removeItem('AccType')
        localStorage.removeItem('Admin')
        localStorage.setItem("beforepath", to.fullPath)
        next({ name: 'Signin' })
      }else{
        console.log("Token Not Expire")
      }
    })
  }

  if (to.name !== 'Signin' && to.name !== 'Callback' && to.name !=='Page500' && token === null){
    localStorage.setItem("beforepath", to.fullPath)
    next({ name: 'Signin' })
  }else if(to.name === 'Announcement' || to.name === 'Canvas' || to.name === 'KC-Moodle' || to.name === 'Guide'){
    if(Acctype !== "Employee"){
      // next({ name: 'Students' })
      next({ name: 'Nopermission' })
    }else{
      next()
    }
  }else{
    next()
  }
})

export default router